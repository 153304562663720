import { Main } from '../../../components/templates/sites/main/Main'
import { PanelTemplate } from '../../../components/templates/sites/PanelTemplate'
import styles from './styles.module.scss'
import { Ul } from '../../../components/ui/ul/Ul'
import { useSelector } from 'react-redux'

export const ContentTemplate = ({children, ...prpops}) => {
  const store = useSelector(state => state)
  const selectedSiteId = store?.AuthReducer?.selectedSiteId??null
  const siteIdParams = `${selectedSiteId?`?siteId=${selectedSiteId}`:''}`

  const sidebar = (
    <>
      <Ul className={styles.mainSidebarMenu}
        name = "mainSidebarMenu"
        list={[
          {
            label: 'Категории тэгов',
            to:`/sites/tags/categories${siteIdParams}`
          },

          {
            label: 'Тэги',
            to:`/sites/tags${siteIdParams}`
          },
        ]}
      />
    </>
  )

  return (
    <PanelTemplate>
      <Main
        sidebar = {{
          type: 'default',
          inner: sidebar
        }}

        content = {{
          type: 'default',
          inner: children??''
        }}

        settings = {{
          border: true,
          borderRadius: true
        }}
      />
    </PanelTemplate>
  )
}