import { Main } from '../../../components/templates/sites/main/Main'
import { PanelTemplate } from '../../../components/templates/sites/PanelTemplate'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './styles.module.scss'
import { Ul } from '../../../components/ui/ul/Ul'
import classNames from 'classnames'
import { Input } from '../../../components/ui/input/Input'
import { Title } from '../../../components/ui/title/Title'
import { Button } from '../../../components/ui/button/Button'
import { Icon } from '../../../components/ui/icons/Icon'
import { Accordion } from '../../../components/ui/accordion/Accordion'
import { Rows } from '../../../components/ui/rows/Rows'
import { ContentTemplate } from './ContentTemplate'
import { CustomSection } from '../../../components/ui/customSection/CustomSection'
import { Modal } from '../../../components/ui/modal/Modal'
import { useState, useEffect, useRef, useReducer} from 'react'
import axios from 'axios'
import Select from 'react-select'
import { CpnSelect } from '../../../components/ui/select/Select'
import { getTags, getCategoriesTag } from '../../../queries/tags'
import { AppFetch } from '../../../utils/form_module'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {AppGlobalModule} from '../../../utils/default_data_app_module'
import { CategoriesTagFormator, TagsFormator, selectFormator } from '../../../utils/formator_module'



const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return {
        ...state,
        name: action.payload
      }
      break
    case 'SET_CATEGORY_ID':
      return {
        ...state,
        categoryId: action.payload
      }
      break
    default:
      return state
      break
  }
}


export const Tags = () => {
  const [ENV, Authorization] = AppGlobalModule()
  const [stateAddNewTagBox, setstateAddNewTagBox] = useState(() => false)
  const [tags, setTags] = useState([])
  const [optionsCategories, setOptionsCategories] = useState([])
  const [state, dispatch] = useReducer(formReducer, {})
  const refFieldTag = useRef()


  //==>>
  const handleToggleChangeBoxSatateNewTag = () => {
    setstateAddNewTagBox(currentValue => !currentValue)
  }

  //==>>
  const handleCreateNewTag = () => {
    const formData = new FormData()
    const categoryId = Number(state?.categoryId)
    const categoryName = state.name
    
    if(categoryId&&categoryName) {
      formData.append('site_id', 17888)
      formData.append('department_id', 18993)
      formData.append('category_id', categoryId)
      formData.append('tag_name', categoryName)
      formData.append('comment_needed', 0)
      AppFetch(`${ENV?.REACT_APP_API_URL}api/tag/create`, {
        method: 'post',
        headers: {
          'Authorization': Authorization,
        },
        body:formData
      }).then(response => {
        if(!response?.result) return
        toast.success("Успешное добавление!", {
          position: toast.POSITION.TOP_RIGHT
        })
        handleToggleChangeBoxSatateNewTag()
        //console.log('response', response)
      
      }).catch(error => {
        console.error('error >>', error)
      })
    } else {
      if(!categoryId) {
        toast.error("Категори тега не указана", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      if(!categoryName) {
        toast.error("Название тега не указано", {
          position: toast.POSITION.TOP_RIGHT
        })
      } 
     
    }
  }

  //==>>
  const handleBlurFieldName = () => {
    const newTagName = refFieldTag?.current?.value??null
    if(!newTagName) return
    dispatch({
      type: 'SET_NAME',
      payload: newTagName
    })
  }

  //==>>
  const setSelectedOption = option => {
    dispatch({
      type: 'SET_CATEGORY_ID',
      payload: option.id
    })
  }

  const sidebar = (
    <>
      <Ul className={styles.mainSidebarMenu}
        name = "mainSidebarMenu"
        list={[
          {
            label: 'Категории тэгов',
            to:'/sites/tags/categories'
          },

          {
            label: 'Тэги',
            to:'/sites/tags'
          },
        ]}
      />
    </>
  )
  
  useEffect(() => {
    //==>> Categories: Options
    // getCategoriesTag({formator:'options'}).then(response => {
    //   setOptionsCategories(response)
    // })
    axios({
      method: 'get',
      url: `${ENV?.REACT_APP_API_URL}api/tag/categoriesList`,
      headers: {
        'Authorization': Authorization,
      }
    })
    .then(response => {
      if(!response?.data?.result) return
      const categoriesList = selectFormator(response.data.categories)
      setOptionsCategories(categoriesList)
    }).catch(error => {
      console.error(error)
    })

    // //==>> TagsList
    // getTags().then(response => {
    //   setTags(response)
    //   console.log('tagsList>>>2', response)
    // })

    axios({
      method: 'get',
      url: `${ENV?.REACT_APP_API_URL}api/tag/tagsList`,
      headers: {
        'Authorization': Authorization,
      }
    })
    .then(response => {
      if(!response?.data?.result) return
      const tagsList = TagsFormator(response.data.tags)
      setTags(tagsList)
    }).catch(error => {
      console.error(error)
    })

  }, [])

  //===>> Format view data
  const getMatchedTags = (categoryId) => {
    return tags.reduce((ac, row) => {
      //if(!ac) ac = []
      if(categoryId===Number(row.category_id)) {
        //console.log('ac', ac, 'OptionId:'+categoryId, 'CategoryId:'+row.category_id, row?.tag_name)
        ac.push({
          label: row?.tag_name??'',
          actions: {
            delete: {
              disabled: true,
              text: null
            },
            eye: {
              disabled: false,
              state: 'opened',
              text: null
            }
          }
          
        })
        return ac
      }
    }, [])
  }

  const viewData = optionsCategories?.map(item => {
    const categoryId = Number(item.id)
    //console.log('tags', tags)
    const matchedTags = getMatchedTags(categoryId)
    //console.log('matchedTags', matchedTags)
    return {
      label: item.label,
      content: <Rows data={matchedTags??[]}/>
    }
  })

  const contentPage = (
    <>
      <CustomSection type="head" className={classNames(styles.section, styles.mainContentHead)}>
        <Input type="search" name="search" placeholder="Введите запрос и нажмите Enter" size="666px"/>
      </CustomSection>
      <CustomSection type="body" className={classNames(styles.section, styles.mainContentBody)}>
        <div className={classNames(styles.mainContentBodyTop)}>
          <Title className={styles.mainContentBodyTopTitle} level={2}>Управление тэгами</Title>
          <div className={classNames(styles.mainContentBodyTopActions)}>
            <Button theme="success" onClick={handleToggleChangeBoxSatateNewTag}>
              <Icon name="plus" bg="#fff" className={styles.iconBtn}/>Добавить тэг
            </Button>
          </div>
        </div>
        {
          stateAddNewTagBox?
          <div className={styles.formAddNewTag}>
            <div className={styles.formAddNewTagInner}>
              <div className={styles.formAddNewTagFieldGrp}>
                <div className={styles.formAddNewTagFieldGrpField}>
                  <Input type="text" name="tagName" placeholder="Введите тэг и нажмите Enter" className={`${styles.formAddNewTagFiledElemInput} `}  size="40%" fieldRef={refFieldTag} onBlur={handleBlurFieldName}/>
                  <CpnSelect options={optionsCategories} className={`${styles.formAddNewTagFiledElemSelect} `} size="60%" onChange={setSelectedOption}/>
                </div>
                <div className={styles.formAddNewTagFieldGrpCheck}>
                  <Input type="checkbox" label="Клиента" name="client" className={styles.formAddNewTagFieldGrpCheckItem}/>
                  <Input type="checkbox" label="Диалога" name="dialog" className={styles.formAddNewTagFieldGrpCheckItem}/>
                  <Input type="checkbox" label="Сообщения" name="message" className={styles.formAddNewTagFieldGrpCheckItem}/>
                </div>
              </div>
              <div className={styles.formAddNewTagActions}>
                <Button theme="default" className={styles.formAddNewTagActionsBtn} onClick={handleCreateNewTag}><Icon name="validate" bg="#fff" size="15px" className={styles.iconBtn}/></Button>
                <Button theme="danger" className={styles.formAddNewTagActionsBtn} onClick={handleToggleChangeBoxSatateNewTag}><Icon name="close" bg="#fff" size="15px" className={styles.iconBtn}/></Button>
              </div>
            </div>
          </div>
          :null
        }
        <div className={classNames(styles.mainContentBodyInner)}>
          <div className={classNames(styles.mainContentBodyLabels)}>
            <span className={classNames(styles.mainContentBodyLabelsItem)}>Сообщение</span>
            <span className={classNames(styles.mainContentBodyLabelsItem)}>Действия</span>
          </div>
          <div className={classNames(styles.mainContentBodyAccordions)}>
            {<Accordion data={viewData} />}
          </div>
        </div>
      </CustomSection>
      <ToastContainer />
    </>
  )
  

  
  return <ContentTemplate>{contentPage}</ContentTemplate>
}