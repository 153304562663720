import { Rows } from '../components/ui/rows/Rows'
import { Modal } from '../components/ui/modal/Modal'

export const CategoriesTagFormator = data => {
  if(data?.length>0) {
    return data.map(item => {
      return {
        ...item,
        label: item.name,
        actions: {
          delete: {
            disabled: true,
            text: null
          },
          move: {
            disabled: true,
            text: null
          },
          eye: {
            disabled: true,
            state: 'opened', // opened||closed||bloqued
            text: null
          }
        }

      }
    })
  } else {
    return null
  }
}

export const TagsFormator = data => {
  if(data?.length>0) {
    return data.map(item => {
      return {
        ...item,
        label: item.name,
        content: <Rows data={[
          {
            label: "Готов к покупке",
            actions: {
              delete: {
                disabled: true,
                text: null,
                alert: <Modal></Modal>
              },
              list: {
                disabled: true,
                text: null
              },
              eye: {
                disabled: true,
                state: 'opened', // opened||closed||
                text: null
              },
              list: {
                disabled: true,
                text: null
              },
              status: {
                disabled: false,
                text: "Комментарий",
                color: "green"
              }
            }
          },
          {
            label: "Сомневается",
            actions: {
              delete: {
                disabled: false,
                text: null
              },
              list: {
                disabled: false,
                text: null
              },
              eye: {
                disabled: false,
                state: 'opened', // opened||closed||
                text: null
              },
              list: {
                disabled: false,
                text: null
              }
            }
          },
          {
            label: "Рассрочка",
            actions: {
              delete: {
                disabled: false,
                text: null
              },
              list: {
                disabled: false,
                text: null
              },
              eye: {
                disabled: false,
                state: 'opened', // opened||closed||
                text: null
              },
              list: {
                disabled: false,
                text: null
              },
              status: {
                disabled: false,
                text: "Комментарий",
                color: "red"
              }
            }
          },
          {
            label: "Вопросы по оплате",
            actions: {
              delete: {
                disabled: false,
                text: null
              },
              list: {
                disabled: false,
                text: null
              },
              eye: {
                disabled: false,
                state: 'opened', // opened||closed||
                text: null
              },
              list: {
                disabled: false,
                text: null
              },
              status: {
                disabled: false,
                text: "Комментарий",
                color: "green"
              }
            }
          },
          {
            label: "Вопросы по доставке",
            actions: {
              delete: {
                disabled: false,
                text: null
              },
              list: {
                disabled: false,
                text: null
              },
              eye: {
                disabled: false,
                state: 'opened', // opened||closed||stopped
                text: null
              },
              list: {
                disabled: false,
                text: null
              }
            }
          }
        ]} />

      }
    })
  } else {
    return null
  }
}

export const selectFormator = data => {
  if(data?.length>0) {
    return data.map(item => {
      return {
        ...item,
        label: item.name,
        value: item.id
      }
    })
  } else {
    return null
  }
}