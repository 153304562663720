import { Link, NavLink } from 'react-router-dom'
import styles from './styles.module.scss'
import logoImg from './../../../assets/images/logo.svg'
import classNames from 'classnames'
import { Icon } from '../../ui/icons/Icon'
import { useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { switchPrimarySidebar } from '../../../redux/actions/defaultAction'
import { Button } from '../../ui/button/Button'

export const SideBar = (props) => {
  const sidebarIsOpened = useSelector(state => state.defaultReducer.primarySidebarIsOpen)
  const dispatch = useDispatch()
  
  const LinkClassNames = ({isActive}) => classNames(styles.menuItemLink, {
    [styles.menuItemLinkActive]:isActive?true:false
  })
  const handleSwitchPrimarySidebar = () => dispatch(switchPrimarySidebar(false))

  
  useEffect(() => {
    //==> 1
    const windowW = window.innerWidth
    if(windowW<=960) {
      dispatch(switchPrimarySidebar(false))
    } else {
      dispatch(switchPrimarySidebar(true))
    }

    //==> 2
    window.addEventListener('resize', e => {
      e.preventDefault()
      const windowW = window.innerWidth
      if(windowW<=960) {
        dispatch(switchPrimarySidebar(false))
      } else {
        dispatch(switchPrimarySidebar(true))
      }
    })
  }, [])
  
  return (
    <aside 
      className={classNames(styles.sidebar, 
      {
        [styles.sidebarIsOpened]: sidebarIsOpened,
        [styles.sidebarIsClosed]: !sidebarIsOpened
      })
    }>
      <div className={styles.sidebarHead}>
        <Link to="/"><img src={logoImg} alt="Logo"/></Link>
      </div>
      <div className={styles.sidebarBody}>
        <nav className={styles.sidebarNav}>
          <ul className={classNames(styles.menu)}>
            <li className={classNames(styles.menuItem)}>
              <NavLink to="/sites" className={LinkClassNames}>
                <Icon name="site"  size="1.02rem" className={styles.menuItemLinkIcon}/>
                <span className={styles.menuItemLinkText}>Сайты</span>
              </NavLink>
            </li>
            <li className={classNames(styles.menuItem)}>
              <NavLink to="/"  className={LinkClassNames}>
                <Icon name="statistics" size="1.1rem" className={classNames(styles.menuItemLinkIcon, styles.positionStatisticIcon)}/>
                <span className={styles.menuItemLinkText}>Статистика</span>
              </NavLink>
            </li>
            <li className={classNames(styles.menuItem)}>
              <NavLink to="/" className={LinkClassNames}>
                <Icon name="dialog-03" size="0.9rem" className={styles.menuItemLinkIcon}/>
                <span className={styles.menuItemLinkText}>Диалоги</span>
              </NavLink>
            </li>
            <li className={classNames(styles.menuItem)}>
              <NavLink to="/sites/tags"  className={LinkClassNames}>
                <Icon name="tag" size="0.9rem" className={styles.menuItemLinkIcon}/>
                <span className={styles.menuItemLinkText}>Тэги</span>
              </NavLink>
            </li>
            <li className={classNames(styles.menuItem)}>
              <NavLink to="/"  className={LinkClassNames}>
                <Icon name="payment" size="1rem" className={styles.menuItemLinkIcon}/>
                <span className={styles.menuItemLinkText}>Оплата</span>  
              </NavLink>
            </li>
            <li className={classNames(styles.menuItem)}>
              <NavLink to="/"  className={LinkClassNames}>
                <Icon name="code-tag" size="1rem" className={styles.menuItemLinkIcon}/>
                <span className={styles.menuItemLinkText}>Разработчикам</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.sidebarFooter}></div>
      <Button className={styles.btnControlSidebar} onClick={handleSwitchPrimarySidebar}><Icon name="close" bg="#f44336" /></Button>
    </aside>
  )
}
