export const BASE_URL = `https://chetverikov.dev.callpy.com/api`;

const checkResponse = (res) => {
    if (res.ok) {
      return res.json();
    }
    
    return res.json().then((data) => {
        console.log("inApi", data);
        throw new Error(data.message);
    });
};

export const getContent = (siteId, start, limit) => {
    return fetch(`${BASE_URL}/client/clientsList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer eVJhbGcyOiJSUzI1EjIsImtpZCI6Ij1kY`
      },
      body: JSON.stringify({
          site_id: siteId,
          start: start,
          limit: limit,
      })
    })
    .then(checkResponse)
}