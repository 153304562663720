import styles from './styles.module.scss'
import classNames from 'classnames'
import { Row } from '../row/Row'
import {Sortable, SortableParent } from '../sortable/Sortable'

export const Rows = ({data, ...props}) => {
  //console.log("RowsData", data)
  const rowList = data?.map( (item, index) => <Row data={item} key={item.id??index} />)??'<div>Нет данных</div>'
  return (
    <div className={styles.rows}><Sortable data={data} Item={Row} /></div>
  )
}