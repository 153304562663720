import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import QuantityPic from '../../../../assets/images/clientsList/users-quantity-pic.svg'
import ButtonAddPic from '../../../../assets/images/clientsList/head-button-add.svg'
import ButtonFilterPic from '../../../../assets/images/clientsList/head-button-filter.svg'
import ButtonMorePic from '../../../../assets/images/clientsList/head-button-more.svg'
import ClientsListDropdownMenu from '../ClientsListDropdownMenu/ClientsListDropdownMenu'

const ClientsListHead = (props) => {
    const initialContextMenu = {
        show: false,
        x: 0,
        y: 0,
    }

    const [contextMenu, setContextMenu] = useState(initialContextMenu);

    // ConstextMenu

    const handleClickUser = (e) => {
        e.preventDefault();
        /*
        const { clientX, clientY } = e;
        setContextMenu({ show: true, x: clientX, y: clientY});
        */
    }

    const closeContextMenu = () => setContextMenu(initialContextMenu);

    return (
        <div className={classNames(styles.clientsListHead)}>
            { contextMenu.show && <ClientsListDropdownMenu x={contextMenu.x} y={contextMenu.y} closeContextMenu={closeContextMenu} whichCliked={'onActions'}/> }
            <div className={classNames(styles.clientsListHead__container)}>
                <img src={QuantityPic} alt='Количество контактов' className={classNames(styles.clientsListHead__quantityPic)}/>
                <p className={classNames(styles.clientsListHead__quantityText)}>{props.countAllUsers}</p>
            </div>
            <ul className={classNames(styles.clientsListHead__container, styles.clientsListHead__buttonList)}>
                <li className={classNames(styles.clientsListHead__buttonListElement)}>
                    <button type='button' className={classNames(styles.clientsListHead__buttonActions)} onClick={(e) => handleClickUser(e)}>Действия</button>
                </li>   
                <li className={classNames(styles.clientsListHead__buttonListElement)}>
                    <button type='button' className={classNames(styles.clientsListHead__buttonCommon)}>
                        <img src={ButtonAddPic} alt='Добавить' className={classNames(styles.clientsListHead__buttonCommonPic)}/>
                    </button>
                </li>
                <li className={classNames(styles.clientsListHead__buttonListElement)}>
                    <button type='button' className={classNames(styles.clientsListHead__buttonCommon)}>
                        <img src={ButtonFilterPic} alt='Фильтр' className={classNames(styles.clientsListHead__buttonCommonPic)}/>
                    </button>
                </li>
                <li className={classNames(styles.clientsListHead__buttonListElement)}>
                    <button type='button' className={classNames(styles.clientsListHead__buttonCommon)}>
                        <img src={ButtonMorePic} alt='Ещё' className={classNames(styles.clientsListHead__buttonCommonPic)}/>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default ClientsListHead;