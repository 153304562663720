import classNames from 'classnames'
import styles from './styles.module.scss'
import { useEffect, useRef, useState, useCallback } from 'react'
import { nanoid } from 'nanoid'


export const Sortable = ({data, Item, dragStartCallBack, dropCallBack, dragOverCallBack, dragLeaveCallBack, dragEndCallBack, ...props}) => {
  const [items, setItems] = useState(data??[])
  const [currentItem,  setCurrentItem] = useState(null)
  const [overItem,  setOverItem] = useState(null)


  const selectedClassName = styles['sortableItem--selected']
  const overedClassName = styles['sortableItem--overed']
  
  
  
  const verticalMoovElem = (opt={}) => {
    const hoveredItem = opt.hoveredItem??null
    const selectedItem = opt.selectedItem??null
    const event = opt.event??null

    if(!selectedItem.id || !hoveredItem.id || !event) return

    const hoveredElem = document.body.querySelector(`[data-id="${hoveredItem.id}"]`)
    const selectedElem = document.body.querySelector(`[data-id="${selectedItem.id}"]`)
    if(!hoveredElem || !selectedElem) return

    //==> DragOver
    if(event.type==='dragover') {
      const hoveredElemStyle = getComputedStyle(hoveredElem)
      const hoveredElemClientInfos = hoveredElem.getBoundingClientRect()

      const hoveredElemHeight = parseInt(hoveredElemStyle.marginTop) + parseInt(hoveredElemStyle.marginBottom)  +  parseInt(hoveredElemStyle.height)
      const cursorPositionY = event.clientY
      const hoveredElemCenter = hoveredElemClientInfos.y + (hoveredElemClientInfos.height / 2)

      const hoveredVerticalMoov = (cursorPositionY>hoveredElemCenter)? `${hoveredElemHeight}px`:`-${hoveredElemHeight}px`
      const selectedVerticalMoov = (cursorPositionY>hoveredElemCenter)? `-${hoveredElemHeight}px`:`${hoveredElemHeight}px`

      hoveredElem.style.cssText = `
        transform: translate3d(0px, ${hoveredVerticalMoov}, 0px);
      `
      selectedElem.style.cssText = `
        transform: translate3d(0px, ${selectedVerticalMoov}, 0px);
      `
    }
  }

  //==> Start 
  const dragStartHandler = (e, item) => {
    const selectedItem = e.currentTarget
    selectedItem.classList.add(selectedClassName)
    setCurrentItem(item)
    if(dragStartCallBack) dragStartCallBack(e, item)
  }

  //==> Drop
  const dropHandler = (e, item) => {
    e.preventDefault()
    setItems(items.map(c => {
      if(c.id === item.id) {
        return {...c, order:currentItem?.order??item.order}
      }
      if(c.id === currentItem?.id??item.id) {
        return {...c, order:item.order}
      }
      return c
    }))
    const selectedItem = e.currentTarget
    selectedItem.classList.remove(selectedClassName)
    selectedItem.classList.remove(overedClassName)
    if(dropCallBack) dropCallBack(e, item)
  }

  //==> Over
  const dragOverHandler = (e, item) => {
    const hoveredElem = e.currentTarget
    if(hoveredElem.classList.contains(selectedClassName)) return
    hoveredElem.classList.add(overedClassName)
    setOverItem(item)
    //verticalMoovElem({selectedItem:currentItem, hoveredItem:item, event:e})
    if(dragOverCallBack) dragOverCallBack(e, item)
    e.preventDefault()
  }

  //==> Leave
  const dragLeaveHandler = (e, item) => {
    const hoveredElem = e.currentTarget
    hoveredElem.classList.remove(overedClassName)
    if(dragLeaveCallBack) dragLeaveCallBack(e, item)
  }

  //==> End
  const dragEndHandler = (e, item)=> {
    const selectedItem = e.currentTarget
    selectedItem.classList.remove(selectedClassName)
    selectedItem.classList.remove(overedClassName)
    setCurrentItem(null)
    if(dragEndCallBack) dragEndCallBack(e, item)
  }


  const sortItem = (a, b) => a.order - b.order

  // const init = useCallback(() => {
  //   data.forEach((elem, index) => {
  //     elem.order=(index+1)
  //     const idElem = nanoid()
  //     if(!elem.id) elem.id = idElem
  //   })
  // }, [])
  // init()

  // data.forEach((elem, index) => {
  //   elem.order=(index+1)
  //   const idElem = nanoid()
  //   if(!elem.id) elem.id = idElem
  // })

  //console.log('itemsSortable>>', items)
  
  return (
    <>
    {
      data.sort(sortItem).map((item, index) => {
        item.draggable = true
        item.order=(index+1)
        const idItem = nanoid()
        if(!item.id) item.id = idItem

        return <Item 
          data={item} 
          key={item.id??nanoid()??index}
          draggable = {true}
          data-sortable = 'item'
          data-id = {item.id}
          onDragStart = {e => dragStartHandler(e, item)}
          onDrop = {e => dropHandler(e, item)}
          onDragOver = {e => dragOverHandler(e, item)}
          onDragLeave = {e => dragLeaveHandler(e, item)}
          onDragEnd = {e => dragEndHandler(e, item)}
          />
      })
    }
    </>
  )
}
