import Select from 'react-select'
import { createUseStyles } from 'react-jss'
import { getColor } from '../../../assets/Js/modules/styles/styles.module'



export const CpnSelect = ({options, size, ...props}) => {
  const useStyles = createUseStyles({
    field: {
      width: size??'100%',    
    }
  })
  const classes = useStyles()
  const colourStyles = {
    control: (styles) => ({
      ...styles, 
      width: '100%',
      height: '36px',
      minHeight: '36px',
      backgroundColor: getColor({color:'white'}),
      borderColor: getColor({color:'gray', level:3}),
      borderWidth: '2px'
    }),
    input: (styles) => ({ 
      ...styles, 
      
    }),
    indicatorSeparator: (styles) => ({ 
      ...styles, 
      display: 'none'
    }),
    indicatorsContainer: (styles) => ({ 
      ...styles, 
      color: getColor({color:'green'})
      
    }),

  }

  if(!options) return null
  return (
    <Select 
      options={options}
      className={classes.field}
      styles={colourStyles}
      {...props}
    />
  )
}