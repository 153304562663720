import { SET_AUTH_LOGIN } from '../types'
import { AppGlobalModule } from '../../utils/default_data_app_module'
const localstorageName = 'callpy_R_state'
const storage = JSON.parse(localStorage.getItem(localstorageName))

const initialState = storage?storage.AuthReducer:{
  isLogin: false,
  token: null,
  user: null,
  selectedSiteId: null
}

export const AuthReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_AUTH_LOGIN:
      return {
        ...state,
        isLogin: action.data.isLogin,
        token: action.data.token
      }
      break
    default:
      return state
    break
  }
}