
import { ContentTemplate } from './ContentTemplate'
import { Input } from '../../../components/ui/input/Input'
import { Title } from '../../../components/ui/title/Title'
import { Button } from '../../../components/ui/button/Button'
import { Icon } from '../../../components/ui/icons/Icon'
import { Rows } from '../../../components/ui/rows/Rows'

import classNames from 'classnames';
import styles from './styles.module.scss'
import { CustomSection } from '../../../components/ui/customSection/CustomSection'
import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { AppFetch } from '../../../utils/form_module'
import { CategoriesTagFormator } from '../../../utils/formator_module'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {AppGlobalModule} from '../../../utils/default_data_app_module'



export const CategoriesTags = () => {
  const [ENV, Authorization] = AppGlobalModule()
  const [addCategorieBox, setAddCategorieBox] = useState(() => false)
  const [categories, setCategories] = useState([])
  const [fieldCategoryValue, setFieldCategoryValue] = useState('')
  const refFieldCategory = useRef()
  

  const handleFormCategoryBox = () => {
    setAddCategorieBox(!addCategorieBox)
  }
  const handleChangeFieldCategory = () => {
    setFieldCategoryValue(refFieldCategory?.current?.value??'')
  }
  const handleCreateNewCategory = () => {
    const newCategoryName = refFieldCategory?.current?.value??null
    if(newCategoryName) {
      const formData = new FormData()
      formData.append('site_id', 17888)
      formData.append('department_id', 18993)
      formData.append('name', newCategoryName)
      AppFetch(`${ENV?.REACT_APP_API_URL}api/tag/createCategory`, {
        method: 'post',
        headers: {
          'Authorization': Authorization,
        },
        body:formData
      }).then(response => {
        if(!response?.result) return
        
        setCategories([
          ...categories,
          {
            id: response.id,
            name: newCategoryName,
            label: newCategoryName,
            actions: {
              delete: {
                disabled: false,
                text: null
              },
              move: {
                disabled: false,
                text: null
              },
              eye: {
                disabled: false,
                state: 'opened', // opened||closed||bloqued
                text: null
              }
            }
          }
        ])
        toast.success("Успешное добавление!", {
          position: toast.POSITION.TOP_RIGHT
        })
        handleFormCategoryBox()
        //console.log('fieldCategoryValue', fieldCategoryValue)
      }).catch(error => {
        console.log('error >>', error)
      })
      //console.log('refFieldCategory', newCategoryName)
    } else {
      toast.error("Название категория не указана", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }
  
  useEffect(() => {
    AppFetch(`${ENV?.REACT_APP_API_URL}api/tag/categoriesList`, {
      method: 'get',
      headers: {
        'Authorization': Authorization,
      }
    }).then(response => {
      if(!response?.result) return
      const categoriesList = CategoriesTagFormator(response.categories)
      setCategories(categoriesList)
    }).catch(error => {
      console.log('error >>', error)
    })

  }, [])

  //console.log('categories >>>', categories)
  const contentPage = (
    <>
      <CustomSection type="head" className={classNames(styles.section, styles.mainContentHead)}>
        <Input type="search" name="search" placeholder="Введите запрос и нажмите Enter" size="666px"/>
      </CustomSection>
      <CustomSection type="body" className={classNames(styles.section, styles.mainContentBody)}>
        <div className={classNames(styles.mainContentBodyTop)}>
          <Title className={styles.mainContentBodyTopTitle} level={2}>Управление категориями</Title>
          <div className={classNames(styles.mainContentBodyTopActions)}>
            <Button theme="success" onClick={handleFormCategoryBox}>
              <Icon name="plus" bg="#fff" className={styles.iconBtn}/>Добавить категорию
            </Button>
          </div>
        </div>
        {
          addCategorieBox?
          <div className={styles.formAddNewCategory}>
            <div className={styles.formAddNewCategoryInner}>
              <Input type="text" name="categoryName" placeholder="Введите название категории и нажмите Enter" className={styles.formAddNewCategoryFiledBox} fieldRef={refFieldCategory} value={fieldCategoryValue} onChange={handleChangeFieldCategory}/>
              <div className={styles.formAddNewCategoryActions}>
                <Button theme="default" className={styles.formAddNewCategoryActionsBtn} onClick={handleCreateNewCategory}><Icon name="validate" bg="#fff" size="15px" className={styles.iconBtn}/></Button>
                <Button theme="danger" className={styles.formAddNewCategoryActionsBtn} onClick={handleFormCategoryBox}><Icon name="close" bg="#fff" size="15px" className={styles.iconBtn}/></Button>
              </div>
            </div>
          </div>
          :null
        }
       

        <div className={classNames(styles.mainContentBodyInner)}>
          <div className={classNames(styles.mainContentBodyAccordions)}><Rows data={categories} sortable={true}/></div>
        </div>
      </CustomSection>
      <ToastContainer />
    </>
  )
  return (
    <ContentTemplate>{contentPage}</ContentTemplate>
  )
}