import styles from './styles.module.scss'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { CustomLink } from '../customLink/CustomLink'

export const Ul = ({children, list, name, ...props}) => {
  const listName = name??'list'
  const ulList = list?.map((item, index) => {
    const itemClass = ({isActive}) => {
      return classNames(styles.ulItemLink, styles[`${listName}ItemLink`], {
        [styles[`${listName}ItemLinkActive`]]: isActive
      })
    }
    return (
      <li className={classNames(styles.ulItem, styles[`${listName}Item`])} key={index}>
        <CustomLink to={item.to??'#'} className={`${styles.ulItemLink}, ${styles.ulItemLink, styles[`${listName}ItemLink`]}`} activeClassName={styles[`${listName}ItemLinkActive`]}>{item.label??`item${index}`}</CustomLink>
      </li>
    )
  })
  return (
    <ul className={classNames(styles.ul, styles[listName])}>{ulList}</ul>
  )
}