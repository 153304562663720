/*
-------------------------------
* FETCH
-------------------------------
*/
export const  AppFetch = async (url, options=null) => {
  try {
    return await fetch(url, (options)? options:{method: 'GET'})
    .then(response => response.json())
  } catch (e) {
    console.error('Error >>>', e)
    return e
  }
}