import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import * as mainApi from '../../../../assets/Js/API/ClientsApi';

import Pagination from '../ClientsListPagination/ClientsListPagination';

const ClientesListFilterNumberBar = (props) => {
    const [selectedRadioBtn, setSelectedRadioBtn] = useState('10');

    const isRadioSelected = (value) => {
        return selectedRadioBtn === value;
    }

    const handleRadioClick = (e) => {
        setSelectedRadioBtn(e.currentTarget.value);
        props.setLimitUsers(Number(e.currentTarget.value));
        props.setCurrentPage(1);
    }

    useEffect(() => {
        mainApi.getContent(props.initial.siteId, props.initial.start, Number(selectedRadioBtn))
            .then((data) => {
                props.setUsers(data.clients);
            })
            .catch((err) => console.log(`err: ${err}`));
    }, [selectedRadioBtn]);

    return (
        <div className={classNames(styles.clientsListFilterNumberBar)}>
            
            <Pagination 
                countAllUsers={props.countAllUsers}
                limitUsers={props.limitUsers}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                selectedRadioBtn={selectedRadioBtn}
                initial={props.initial}
                setUsers={props.setUsers}
            />
                
            <div className={classNames(styles.filterNumberBar__rightPart)}>
                <form className={classNames(styles.filterNumberBar__formShow)}>
                    <label className={classNames(styles.filterNumberBar__formLabel)}>Показывать по</label>
                    <div className={classNames(styles.filterNumberBar__formButtonContainer)}>
                        <input 
                            type='radio'
                            className={classNames(styles.filterNumberBar__formButton, styles.filterNumberBar__formButton_10)}
                            value='10'
                            checked={isRadioSelected('10')}
                            onChange={handleRadioClick}
                        />
                    </div>
                    <div className={classNames(styles.filterNumberBar__formButtonContainer)}>
                        <input 
                            type='radio'
                            className={classNames(styles.filterNumberBar__formButton, styles.filterNumberBar__formButton_20)}
                            value='20'
                            checked={isRadioSelected('20')}
                            onChange={handleRadioClick}
                        />
                    </div>
                    <div className={classNames(styles.filterNumberBar__formButtonContainer)}>
                        <input 
                            type='radio'
                            className={classNames(styles.filterNumberBar__formButton, styles.filterNumberBar__formButton_50)}
                            value='50'
                            checked={isRadioSelected('50')}
                            onChange={handleRadioClick}
                        />
                    </div>
                    <div className={classNames(styles.filterNumberBar__formButtonContainer)}>
                        <input 
                            type='radio'
                            className={classNames(styles.filterNumberBar__formButton, styles.filterNumberBar__formButton_100)}
                            value='100'
                            checked={isRadioSelected('100')}
                            onChange={handleRadioClick}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ClientesListFilterNumberBar;