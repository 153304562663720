import React, { useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { contextMenuItems, contextMenuItemsActions } from '../utils/constants/contextMenuItems'
import useOnClickOutside from '../../../../assets/Js/hooks/useOnClickOutside'


const ClientsListDropdownMenu = ({x, y, closeContextMenu, whichCliked}) => {
    const contextMenuRef = useRef(null);
    useOnClickOutside(contextMenuRef, closeContextMenu);
    let actualMenuItems = (whichCliked === 'onActions') ? contextMenuItemsActions : contextMenuItems

    return (
        <ul 
            ref={contextMenuRef}
            className={classNames(styles.dropdown__container)}
            style={{top: `${y}px`, left: `${x}px`}}            
        >
            {
                actualMenuItems.map((item) => 
                    <li
                        key={item.name}
                        onClick={() => {
                            closeContextMenu();
                            item.onClick();
                        }}
                        className={classNames(styles.dropdown__element)}
                    >
                        <button type='button' className={classNames(styles.dropdown__elementButton)}>
                            <img src={item.icon} alt={item.name} className={classNames(styles.dropdown__elementIcon)}/>
                            <p className={classNames(styles.dropdown__elementText)}>{item.name}</p>
                        </button>
                    </li>
                )
            }
        </ul>
    );
};

export default ClientsListDropdownMenu;