import { useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

export const Dropdown = ({label, children, arrowLabel, labelClassName, listClassName, className, ...props}) => {
  const [state, setState] = useState(()=> false)
  const ref = useRef(null)

  const HandleOpenDropdown = useCallback((event) => {
    const targetIsLabel = ref?.current?.contains(event.target)
    if(targetIsLabel) return setState(currentState => !currentState)
    setState(false)
  }, [])
  
  useEffect(()=> {
    document.addEventListener('click', HandleOpenDropdown, true)
  }, [])


  return (
    <div className={classNames(styles.dropdown, className??'', {
      [styles.dropdownOpen]: state
    })}>
      <button className={classNames(styles.dropdownLabel, labelClassName??'', {
        [styles.dropdownLabelArrow]: arrowLabel??null
      })} ref={ref} >
        {label??'Dropdown label'}
      </button>
      <div className={classNames(styles.dropdownContent, listClassName??'', {
        [styles.dropdownContentOpen]: state
      })}>{children}</div>
    </div>
  )
}