import { COLORS } from './colors.module'

let currentTheme = 'light'

/**
 * 
 * @param {*} color 
 * @param {*} type (primary | systeme)
 * @returns 
 */
export const getColor = (opt={}) => {
  const color = opt.color??'black'
  const level = opt.level? (opt.level<10?`0${opt.level}`:opt.level):null
  const type = opt.type??'system'
  
  const colors = currentTheme === 'light' ? COLORS.lightThemeColors : COLORS.darkThemeColors

  //console.log('level', level)

  return level? colors[type][color][level]:colors[type][color]
}

// export const scrollBar$404 = (opt={}) => {
//   const w = opt.width??'3px'
//   const h = opt.height??'3px'

//   return {
//     width: w,
//     height: h,
//   }
// }