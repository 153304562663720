import { ModalContent } from './ModalContent'

export const Modal = ({children, show, ...props}) => {
  if(!show) return
  console.log('children', children)
  return (
    <ModalContent>
      {children}
    </ModalContent>
  ) 
}