import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import thunk from 'redux-thunk'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { rooteReducer } from './redux/reducer'
import { App } from './App';
import './assets/styles/styles.scss'
//import { defaultReducer } from './redux/reducer/defaultReducer'
const appRoot = document.getElementById('root')
const root = ReactDOM.createRoot(appRoot)


const store = createStore(rooteReducer, compose(
  applyMiddleware(
    thunk
  )
  /*,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
))


root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App/>
    </Provider>
  </BrowserRouter>
  
)
