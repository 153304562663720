import styles from './sites_styles.module.scss'
import { CustomSection } from '../../components/ui/customSection/CustomSection'
import { classNames } from 'classnames'
import { PanelTemplate } from '../../components/templates/sites/PanelTemplate'
import { Link } from 'react-router-dom'


export const Sites = () => {

  // const handleSelectSite = (e) => {
  //   console.log('selectedSite', e)
  // }
  const siteId = 17888
  return  (
    <PanelTemplate>
      <Link to={`/sites/tags?siteId=${siteId}`} className={`LinkClassNames`} state={{selectedId:siteId}}>Теги сайта</Link>
    </PanelTemplate>
  )
}