import React, { useEffect } from 'react';

const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (e) => {
            const el = ref.current;            
            if (!el || el.contains((e.target) || null)) {
                return
            }

            handler(e) // Вызывает handler только если клик случался вне элемента
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler]) // Перезагружать только если handler изменился
};

export default useOnClickOutside;