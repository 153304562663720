import { Layout } from '../../layout/Layout'
import { Header }  from './Header'
import { Footer } from './Footer'
import { SideBar }  from './Sidebar'
import styles from './styles.module.scss'

export const PanelTemplate = ({headMain, children, ...props}) => {
  return (
    <Layout>
      <SideBar/>
      <div className={styles.mainApp}>
        <Header/>
        <main className={styles.main}>
          <div className={styles.mainInner}>
            <div className={styles.mainHead}> {headMain??''}</div>
            <div className={styles.mainContent}>{children}</div>
          </div>
        </main>
        <Footer/>
      </div>
    </Layout>
  )
}