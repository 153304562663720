import styles from './styles.module.scss'
import classNames from 'classnames'
import { Item } from './Item'

export const Accordion = ({data, ...props}) => {

  const itemsAccordion = data?.map((item, index) => {
    return (
      <Item key={item?.id??index} label={item.label??`Label-${index}`}>{item.content??<div>Нет данных</div>}</Item>
    )
  })

  return (
    <div className={classNames(styles.accordion)}>{itemsAccordion}</div>
  )
  
}