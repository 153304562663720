const primary = {
  white: '#fff',
  black: '#000',
  yellow: '#',
  orange: '',
  gray: '',
  blue: '#7ABDFF',
  red: '#ff0000',
}

export const lightThemeColors = {
  primary,
  system: {
    success: '#34C759',
    error: '#E53935',
    red: '#E6402E', //yes
    orange: '#FF9500',
    yellow: '#FFCC00',
    green: '#34C759',
    teal: '#5AC8FA',
    blue: '#0077FF', //yes
    indigo: '#5856D6',
    purple: '#AF52DE',
    pink: '#FF2D55',
    gray: {
      '01': '#9D9592', //yes
      '02': '#AEAEB2',
      '03': '#E5EAF3', //yes
      '04': '#D1D1D6',
      '05': '#E5E5EA',
      '06': '#F2F2F7',
      '07': '#8097C3'
    },
    blue_: {
      '01': '#013087',
      '08': '#ecf7f9'
    }
  }
}

export const darkThemeColors = {
  primary,
  system: {
    success: '#34C759',
    error: '#E53935',
    red: '#FF453A',
    orange: '#FF9F0A',
    yellow: '#FFD60A',
    green: '#32D74B',
    teal: '#64D2FF',
    blue: '#0A84FF',
    indigo: '#5E5CE6',
    purple: '#BF5AF2',
    pink: '#FF2D55',
    gray: {
      '01': '#8E8E93',
      '02': '#636366',
      '03': '#48484A',
      '04': '#3A3A3C',
      '05': '#2C2C2E',
      '06': '#1C1C1E',
    },
  }
}

export const COLORS = {
  lightThemeColors,
  darkThemeColors
}
//export const Colors = lightThemeColors | darkThemeColors