import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ClientsListHead from '../ClientsListHead/ClientsListHead';
import ClientsListNavigationAndContent from '../ClientsListNavigationAndContent/ClientsListNavigationAndContent';
import ClientsListFilterNumberBar from '../ClientsListFilterNumberBar/ClientsListFilterNumberBar';
import * as mainApi from '../../../../assets/Js/API/ClientsApi';
import { ContentTemplate } from '../ContentTemplate';

const CrmClientsList = () => {
    const initial = {
        siteId: 17859,
        start: 0,
        limit: 10,
    }
    const [usersData, setUsersData] = useState({});
    const [users, setUsers] = useState([]);
    const [checkedUsersId, setCheckUsersId] = useState([]);
    const [limitUsers, setLimitUsers] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        mainApi.getContent(initial.siteId, initial.start, initial.limit)
            .then((data) => {
                setUsersData(data);
                setUsers(data.clients);

            })
            .catch((err) => console.log(`err: ${err}`));
    }, []);

    const contentPage = (
        <div className={classNames(styles.clientsList)}>
            <ClientsListHead
                users={users}
                countAllUsers={usersData.count}
            />
            <ClientsListNavigationAndContent
                users={users}
                setUsers={setUsers}
                limitUsers={limitUsers}
                currentPage={currentPage}
                checkedUsersId={checkedUsersId}
                setCheckUsersId={setCheckUsersId}
            />
            <ClientsListFilterNumberBar
                countAllUsers={usersData.count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limitUsers={limitUsers}
                setLimitUsers={setLimitUsers}
                initial={initial}
                setUsers={setUsers}
            />
        </div>
    )

    return (
        <ContentTemplate>{contentPage}</ContentTemplate>
    );
};

export default CrmClientsList;