import React from 'react'
import {Routes, Route} from 'react-router-dom'

import { Login } from './pages/Login'
import { CategoriesTags } from './pages/sites/tags/CategoriesTags'
import {Tags} from './pages/sites/tags/Tags'
import { RequireAuth } from './hoc/RequireAuth'
import { Sites } from './pages/sites/Sites'
import { RequireSiteId } from './hoc/RequireSiteId'
import CrmClientsList from './pages/sites/ClientsList/CrmClientsList/CrmClientsList'




export const App = () => (
  <>
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/sites" element={
        <RequireAuth>
          <Sites/>
        </RequireAuth>
      }/>
      <Route path="/sites/tags" element={
        <RequireAuth>
          <RequireSiteId>
            <Tags/>
          </RequireSiteId>
        </RequireAuth>
      }/>
      <Route path="/sites/tags/categories" element={
        <RequireAuth>
          <RequireSiteId>
            <CategoriesTags/>
          </RequireSiteId>
        </RequireAuth>
      }/>
      <Route path="/sites/clients" element={
        <RequireAuth>
          <CrmClientsList/>
        </RequireAuth>
      }/>
    </Routes>
  </>
)