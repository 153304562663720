import {useSelector } from 'react-redux'


export const AppGlobalModule = () => {
  const state = useSelector(state=>state)
  
  //===>>> ENV
  const ENV = process.env??{}

  const localstorageName = ENV.REACT_APP_LOCALSTORAGE_NAME

  //console.log('ENV', localstorageName)

  //===>>> Authorization
  const TOKEN = state?.AuthReducer?.token??null
  const Authorization = `Bearer ${TOKEN}`
  return [ENV,  Authorization, localstorageName]
    
}


