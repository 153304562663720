import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { getPagesUsers } from '../utils/getPagesUsers';
import * as mainApi from '../../../../assets/Js/API/ClientsApi';

import listButtonDisabled from '../../../../assets/images/clientsList/list-next-button_disabled.svg';
import listButtonEnabled from '../../../../assets/images/clientsList/list-next-button_enabled.svg';

const Pagination = (props) => {
    let pageNumbers = [];
    const [nextPageAvalible, setNextPageAvalible] = useState(true);
    const [prevPageAvalible, setPrevPageAvalible] = useState(false);
    
    for (let i = 1; i <= getPagesUsers(props.countAllUsers, props.limitUsers); i++) {
        pageNumbers.push(i);
    }

    const nextPage = () => {
        if (props.currentPage < pageNumbers.length) {
            props.setCurrentPage( prev => prev + 1);            
        }
    }

    const prevPage = () => {
        if (props.currentPage > 1) {
            props.setCurrentPage( prev => prev - 1);
        }
    }    

    const isPrevAvalible = () => {
        if (props.currentPage > 1) {
            setPrevPageAvalible(true);
        } else {
            setPrevPageAvalible(false);
        }
    }

    useEffect(() => {

        if (props.currentPage < pageNumbers.length) {
            setNextPageAvalible(true);
            isPrevAvalible();
        } else {
            setNextPageAvalible(false);
            isPrevAvalible();
        }
        
    }, [pageNumbers, props.currentPage]);

    useEffect(() => {
        mainApi.getContent(props.initial.siteId, ((props.currentPage-1) * Number(props.selectedRadioBtn)), Number(props.selectedRadioBtn))
            .then((data) => {
                props.setUsers(data.clients);
            })
            .catch((err) => console.log(`err: ${err}`));
    }, [props.currentPage])

    return (
        <div className={classNames(styles.filterNumberBar__leftPart)}>
            <button className={classNames(styles.filterNumberBar__arrowButton)} onClick={prevPage}>
                <img src={prevPageAvalible ? listButtonEnabled : listButtonDisabled} alt='' className={classNames(styles.filterNumberBar__arrowButtonPic, styles.filterNumberBar__arrowButtonPic_back)}/>
            </button>
            <div className={classNames(styles.filterNumberBar__listButtons)}>
                {
                    (pageNumbers.length <= 5) // Страницы без дополнительной кнопки
                    ? 
                    pageNumbers.map (p => {     
                        return <button 
                            key={p}
                            type='button'
                            className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                            onClick={() => props.setCurrentPage(p)}
                        >{p}</button>
                    })
                    :
                    (props.currentPage >= (pageNumbers.length - 3)) // Последняя граница
                    ?
                    pageNumbers.map (p => {
                        if (p === 1) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        else if (p >= (pageNumbers.length - 3)) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        else if (p === (pageNumbers.length - 4)) {
                            return <button
                                type='button'
                                key={' •••'}
                                className={classNames(styles.filterNumberBar__listButton)}
                                onClick={() => props.setCurrentPage(pageNumbers.length - 4)}
                            >•••</button>
                        }
                        return null;
                    })
                    :
                    (props.currentPage <= 4) // Первая граница
                    ?
                    pageNumbers.map (p => {
                        if (p <= 4) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        else if (p === 5) {
                            return <button 
                                type='button'
                                key={'••• '} 
                                className={classNames(styles.filterNumberBar__listButton)}
                                onClick={() => props.setCurrentPage(5)}
                            >•••</button>
                        } 
                        else if (p === pageNumbers.length) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        return null;
                    })
                    :
                    ((props.currentPage >= 4) && (props.currentPage <= (pageNumbers.length - 3))) // Средняя граница
                    ?
                    pageNumbers.map (p => {
                        if (p === 1) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        else if (p === props.currentPage - 2) {
                            return <button
                                type='button'
                                key={'••• '}
                                className={classNames(styles.filterNumberBar__listButton)}
                                onClick={() => props.setCurrentPage(prev => prev - 2)}
                            >•••</button>
                        }
                        else if ((p >= props.currentPage - 1) && (p <= props.currentPage + 1)) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        else if (p === props.currentPage + 2) {
                            return <button
                                type='button'
                                key={' •••'}
                                className={classNames(styles.filterNumberBar__listButton)}
                                onClick={() => props.setCurrentPage(prev => prev + 2)}
                            >•••</button>
                        }
                        else if (p === pageNumbers.length) {
                            return <button 
                                key={p}
                                type='button'
                                className={classNames(styles.filterNumberBar__listButton, {[styles.filterNumberBar__listButton_active]: (props.currentPage === p)})}
                                onClick={() => props.setCurrentPage(p)}
                            >{p}</button>
                        }
                        return null;
                    })
                    : null
                }
            </div>
            <button className={classNames(styles.filterNumberBar__arrowButton)} onClick={nextPage}>
                <img src={nextPageAvalible ? listButtonEnabled : listButtonDisabled} alt='' className={classNames(styles.filterNumberBar__arrowButtonPic)}/>
            </button>
        </div>
    );
};

export default Pagination;