import { Main } from '../../../components/templates/sites/main/Main'
import { PanelTemplate } from '../../../components/templates/sites/PanelTemplate'
import { Ul } from '../../../components/ui/ul/Ul'

export const ContentTemplate = ({children, ...prpops}) => {

  return (
    <PanelTemplate>
      <Main
        content = {{
          type: 'default',
          inner: children??''
        }}

        settings = {{
          border: true,
          borderRadius: true
        }}
      />

    </PanelTemplate>
  )
}