import { Button } from '../../ui/button/Button'
import styles from './styles.module.scss'
import { Icon } from '../../ui/icons/Icon'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown } from '../../ui/dropdown/Dropdown'
import classNames from 'classnames'
import {useDispatch, useSelector } from 'react-redux'
import { switchPrimarySidebar } from '../../../redux/actions/defaultAction'
import { setLogin } from '../../../redux/actions/authAction'


export const Header = (props) => {
  const state = useSelector(state => state)
  const sidebarIsOpened = state?.defaultReducer?.primarySidebarIsOpen??false
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const userDropdownLabel = <> <span className={styles.userAvater}></span> <span className={styles.userName}>Чат консультация</span> </>

  const handleSwitchPrimarySidebar = () => dispatch(switchPrimarySidebar(!sidebarIsOpened))

  //==> LOGOUT
  const handleLogOut = () => {
    const userLogoutData = {
      isLogin: false,
      token: null
    }
    console.log('successfull logout')
    dispatch(setLogin(userLogoutData))
    localStorage.removeItem('callpy_R_state')
    navigate('/')
  }
  
  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerLeft}>
          <Button className={styles.btnControlSidebar} onClick={handleSwitchPrimarySidebar}><Icon name="menu-hamburger" bg="#1877ff" /></Button>
        </div>
        <div className={styles.headerRight}>
          <Link to="#" className={styles.bigLink}>
            <Icon name="chat-01" bg="#fff" size="16px" className={styles.bigLinkIcon}></Icon>
            <span className={styles.bigLinkLabel}>Панель коммуникации</span>
          </Link>
          <Dropdown label={userDropdownLabel} labelClassName={styles.userLabel} listClassName={styles.userlist} arrowLabel={true} className={classNames(styles.user)} >
            <div className={classNames(styles.userDropdownContent)}>
              <ul className={classNames(styles.userDropdownContentContacts)}>
                <li className={classNames(styles.userDropdownContentContactsItem)}>
                  <Link to="#" className={classNames(styles.userDropdownContentContactsItemLink)}>
                    89165449232
                  </Link>
                </li>
                <li className={classNames(styles.userDropdownContentContactsItem)}>
                  <Link to="#" className={classNames(styles.userDropdownContentContactsItemLink)}>
                    shop@devcallpy.ru
                  </Link>
                </li>
              </ul>
              <ul className={classNames(styles.userDropdownContentMenu)}>
                <li className={classNames(styles.userDropdownContentMenuItem)}>
                  <Link to="#" className={classNames(styles.userDropdownContentMenuItemLink)}>
                    Редактировать
                  </Link>
                </li>
                <li className={classNames(styles.userDropdownContentMenuItem)}>
                  <Link to="#" className={classNames(styles.userDropdownContentMenuItemLink)}>
                    Изменить пароль
                  </Link>
                </li>
                <li className={classNames(styles.userDropdownContentMenuItem)}>
                  <Link to="#" className={classNames(styles.userDropdownContentMenuItemLink)} onClick={handleLogOut} >
                    Выйти
                  </Link>
                </li>
              </ul>
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  )
}

