import {Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppGlobalModule } from '../utils/default_data_app_module'
import { AppFetch } from '../utils/form_module'
import { setLogin } from '../redux/actions/authAction'
const localstorageName = 'callpy_R_state'

export const RequireAuth = ({children}) => {
  const [ENV, Authorization] = AppGlobalModule()
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isLogin = state?.AuthReducer?.isLogin??false

  if(!isLogin) {
    //==>Verify if have cookie
    AppFetch(`${ENV?.REACT_APP_API_URL}api/login/getToken`, {
      method: 'post'
    }).then(response => {
      
      if(response?.result){
        //==> SetState
        const userLoggedData = {
          isLogin: true,
          token: response.token,
          selectedSiteId: response.selectedSiteId
        }
        dispatch(setLogin(userLoggedData))
      } else {
        navigate('/')
        //return <Navigate to='/'/>
      }
    })
  }

  localStorage.setItem(localstorageName, JSON.stringify(state))
  return children
}