import { Link , useMatch} from 'react-router-dom'
import { classNames } from 'classnames'

export const CustomLink = ({children, to, className, activeClassName, ...props}) => {
  const match = useMatch(to)
  return (
    <Link 
      to={to} 
      className={`${className} ${match?activeClassName??'active':''}`}
      {...props}
    >
      {children}
    </Link>
  )
}