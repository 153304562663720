import styles from './login_styles.module.scss'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Link } from "react-router-dom"
import { getColor } from '../assets/Js/modules/styles/styles.module'
import { Input } from '../components/ui/input/Input'
import { Button } from '../components/ui/button/Button'
import { Formik } from 'formik'
import {useState, useRef } from 'react'
import { AppFetch } from '../utils/form_module'
import {useDispatch, useSelector, } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { setLogin } from '../redux/actions/authAction'
import {Navigate , useNavigate} from 'react-router-dom'
import {AppGlobalModule} from '../utils/default_data_app_module'




const colorError = getColor({color:'error'})
const colorBlue8 = getColor({color:'blue_', level:8})

export const Login = () => {
  const [ENV, Authorization] = AppGlobalModule()
  const emailRef = useRef()
  const passwordRef = useRef()
  const [formError, setFormError] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const state = useSelector(state=>state)

  const useStyles = createUseStyles({
    login: {
      backgroundColor: colorBlue8,
    }
  })
  const classes = useStyles()
  const handleGoToSitesListe = () => navigate('/sites')
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const submitBtn = e.nativeEvent.submitter
    const fields = [emailRef, passwordRef]
    
    const email = emailRef.current.value
    const password = passwordRef.current.value

    if(email&&password) {
      const formData = new FormData()
      formData.append('login', email)
      formData.append('pass', password)
      AppFetch(`${ENV?.REACT_APP_API_URL}api/login/getToken`, {
        method: 'post',
        body:formData
      }).then( response => {
        if(response?.result) {
          const userLoggedData = {
            isLogin: true,
            token: response.token
          }
          dispatch(setLogin(userLoggedData))
          navigate('/sites')
        } else {
          toast.error("Логин или пароль неверный", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        console.log('form response', response)
      })
    } else {
      toast.error("Пожалуйста заполните все поля", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  return (
    <>
      <div className={classNames(classes.login, styles.login)}>
        <div className={classNames(styles.loginContainer)}>
          <div className={classNames(styles.loginBox)}>
            <div className={classNames(styles.loginLogo)}></div>
            {!state?.AuthReducer?.isLogin?
              <>
                <h1 className={classNames(styles.loginTitle)}>Войти в личный кабинет</h1>
                <form className={classNames(styles.loginForm)} onSubmit={handleSubmit}>
                  <Input 
                    type="email" 
                    name="email" 
                    className={classNames(styles.loginField)} 
                    placeholder="Введите Ваш email:" 
                    height="40px"
                    fieldRef={emailRef}
                  />
                  <Input 
                    type="password" 
                    name="password"
                    className={classNames(styles.loginField)}  
                    placeholder="Введите Ваш пароль:" 
                    height="40px"
                    fieldRef={passwordRef}
                  />
                  <div className={classNames(styles.loginFormGrpBtn)}>
                    <Button className={classNames(styles.loginFormBtn)} >Войти</Button>
                  </div>
                </form>
              </>
              : 
              <>
                <Button className={classNames(styles.loginFormBtn)} onClick={handleGoToSitesListe} >{`Перейти к личном кабинету  >>>>`}</Button>
              </>
            } 
            
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}