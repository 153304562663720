import messageIcon from '../../../../../assets/images/clientsList/dropdownMenu/msgbox.svg';
import callIcon from '../../../../../assets/images/clientsList/dropdownMenu/call.svg';
import sendIcon from '../../../../../assets/images/clientsList/dropdownMenu/send.svg';
import curseIcon from '../../../../../assets/images/clientsList/dropdownMenu/lms.svg';
import tagIcon from '../../../../../assets/images/clientsList/dropdownMenu/tag.svg';
import exportIcon from '../../../../../assets/images/clientsList/dropdownMenu/download-upload.svg';
import deleteIcon from '../../../../../assets/images/clientsList/dropdownMenu/delete.svg';
import userIcon from '../../../../../assets/images/clientsList/dropdownMenu/user.svg';

export const contextMenuItems = [
    {
        name: 'Написать',
        icon: messageIcon,
        onClick: () => {}
    },
    {
        name: 'Позвонить',
        icon: callIcon,
        onClick: () => {}
    },
    {
        name: 'Отправить рассылку',
        icon: sendIcon,
        onClick: () => {}
    },
    {
        name: 'Назначить курс',
        icon: curseIcon,
        onClick: () => {}
    },
    {
        name: 'Добавить тег',
        icon: tagIcon,
        onClick: () => {}
    },
    {
        name: 'Экспортировать',
        icon: exportIcon,
        onClick: () => {}
    },
    {
        name: 'Удалить',
        icon: deleteIcon,
        onClick: () => {}
    }
]

export const contextMenuItemsActions = [
    {
        name: 'Сохранить сегмент',
        icon: userIcon,
        onClick: () => {}
    },    
    {
        name: 'Отправить рассылку',
        icon: sendIcon,
        onClick: () => {}
    },
    {
        name: 'Назначить курс',
        icon: curseIcon,
        onClick: () => {}
    },
    {
        name: 'Добавить тег',
        icon: tagIcon,
        onClick: () => {}
    },
    {
        name: 'Экспортировать',
        icon: exportIcon,
        onClick: () => {}
    },
    {
        name: 'Удалить',
        icon: deleteIcon,
        onClick: () => {}
    }
]