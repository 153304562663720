import styles from './styles.module.scss'
import classNames from 'classnames'


export const Title = ({level, className, children, ...props}) => {
  let CustomTag = `h${level??1}`
  return (
    <CustomTag className={classNames(styles.title, className)}>
      {children}
    </CustomTag>
  )
}