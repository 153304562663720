import styles from './styles.module.scss'
import classNames from 'classnames'
import { useState } from 'react'

export const Item = ({label, children, ...props}) => {
  const [opened, setOpened] = useState(()=>false)
  const handleOpen = () => setOpened(!opened)

  return (
    <>
      <dt className={classNames(styles.accordionLabel, {
        [styles.accordionLabelOpen]: opened,
      })} onClick={handleOpen}>{label}</dt>
      <dd className={classNames(styles.accordionContent, {
        [styles.accordionContentOpen]: opened,
        [styles.accordionContentClose]: !opened,
      })}>{children}</dd>
    </>
  )
}