import { createUseStyles } from 'react-jss'
import classNames from "classnames"
import styles  from './styles.module.scss'

const importAll = require => 
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next)
    return acc
  }, {})
const images = importAll(require.context("./svg", false, /\.(png|jpe?g|svg)$/))

export const Icon = ({name, bg, width, height, size, className, disabled, ...props}) => {
  
  const colorPrimaryGrey =   '#929A9D'
  const iconName = `icon-${name??'tag'}.svg`
  
  const useStyles = createUseStyles({
    icon: {
      display: 'inline-block',
      minWidth: width??size??'1.2rem',
      minHeight: height??size??'1.2rem',
      '-webkit-mask-image' : `url(${images[iconName]})`,
      '-webkit-mask-size': `${width??size??'1.2rem'} ${height??size??'1.2rem'}`,
      '-webkit-mask-repeat': 'no-repeat ',
      '-webkit-mask-position': 'center left',
      backgroundColor: bg??colorPrimaryGrey
    },

  })
  
  const classes = useStyles()
  return <i className={classNames(classes.icon, className, {
    [styles.iconDisabled]: disabled
  })}></i>
}

