import classNames from 'classnames'
import styles from './styles.module.scss'
import { createUseStyles } from 'react-jss'
import { Button } from '../../../ui/button/Button'
import { Icon } from '../../../ui/icons/Icon'
import { useState, useEffect} from 'react'

export const Main = ({sidebar, content, settings, ...props}) => {
  const [sidebarIsOpened, setSidebarIsOpened] = useState(true)
  const [showBtnControlSidebar, setShowBtnControlSidebar] = useState(!sidebarIsOpened)

  const settingsStyles = {}
  if(settings) {
    if(settings.border) settingsStyles.border = 'solid 1px #e5eaf3'
    if(settings.borderRadius) settingsStyles.borderRadius = '10px'
  }

  const useStyles = createUseStyles({
    mainContentInner: {
      ...settingsStyles
    },

    defaultSidebar: {
      backgroundColor: '#ECF4FB'
    },

    defaultContent: {
      backgroundColor: '#fff'
    }
  })
  const classes = useStyles()

  const handlerSibbar = e => {
    //console.log('click', e.currentTarget)
    setSidebarIsOpened(!sidebarIsOpened)
    setShowBtnControlSidebar(!showBtnControlSidebar)
  }

  useEffect(() => {
    //==> 1
    const windowW = window.innerWidth
    if(windowW<=960) {
      setSidebarIsOpened(false)
    } else {
      setSidebarIsOpened(true)
    }

    //==> 2
    window.addEventListener('resize', e => {
      e.preventDefault()
      const windowW = window.innerWidth
      if(windowW<=960) {
        setSidebarIsOpened(false)
      } else {
        setSidebarIsOpened(true)
        setShowBtnControlSidebar(false)
      }
      //console.log('windowW', windowW)
    })
  }, [])

  return (
    <div className={classNames(styles.mainContentInner, classes.mainContentInner, {
      [styles.sidebarIsOpened]:sidebarIsOpened,
      [styles.sidebarIsClosed]:!sidebarIsOpened
    })}>
      {
        sidebar&&<aside className={classNames(styles.sidebar, sidebar?.className??'', {
          [classes[`${sidebar.type}Sidebar`]]:sidebar.type
        })}>
          {
            (!sidebarIsOpened||showBtnControlSidebar)&&<Button 
              className={classNames(styles.btnControlSidebar, {
                [styles.btnCloseSidebar]:showBtnControlSidebar
              })} 
              onClick={handlerSibbar} 
            >
              {!showBtnControlSidebar&&<Icon name='menu-hamburger' size="16px" bg="#24353B"/>}
              {showBtnControlSidebar&&<Icon name='close' size="16px" bg="#121415"/>}
            </Button>
          }
          <div className={styles.sidebarInner}>{sidebar.inner??''}</div>
        </aside>
      }

      {
        content&&<div className={classNames(styles.content, content?.className??'', {
        [classes[`${content.type}Content`]]:content.type
        })}>
          {content.inner??''}
        </div>
      }
    </div>
  )
}