import styles from './styles.module.scss'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

export const Button = ({children, className, size, bg, color, theme, disabled, onClick, ...props}) => {
  const btnStyles = {}
  if(size) btnStyles.fontStyle = size
  if(bg) btnStyles.backgroundColor = bg
  if(color) btnStyles.backgroundColor = color

  const useStyles = createUseStyles({
    button: {
      ...btnStyles
    }
  })
  const classes = useStyles()
  //console.log('disabled-->', disabled)
  return (
    <button className={classNames(styles.btn, classes.button, styles[`btn--${theme}`], className, {[styles.btnDisabled]:disabled})} onClick={onClick} {...props}>{children}</button>
  )
}