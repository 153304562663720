import { useState, useCallback, useRef } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { Input } from '../input/Input'


export const EditableText = ({className, value, label, onChange, handleFocusout, ...props}) => {
  const [state, setState] = useState(()=>false)
  const [fieldValue, setFieldValue] = useState(()=>value??'')
  const refField = useRef()
 //console.log('Editable value', value)

  const handleInputBlur = ({field}) => {
    setState(currenteState => !currenteState)
    //console.log('state - set blur', state)
  }

  const handleChangeState = () => {
    setState(currenteState => !currenteState)
  }

  // const handleFocus = e => {
  //   console.log('e', e)
  // }

  // const handleFocusout = () => {
    
  // }

  const viewControl = useCallback(() => {
    if(state) {
      return (
        <Input  
          focus={true} 
          value={fieldValue} 
          //onFocus={handleFocus} 
          onBlur={()=> handleFocusout?handleFocusout({field:refField?.current, state,  handleChangeState}):''} 
          fieldRef={refField} 
          {...props}
        />
      )
    } else {
      return label??fieldValue
    }
  }, [state])

  const changeMode = () => {
    setState(currenteState => !currenteState)
    setFieldValue(value)
    setTimeout(()=> {
      const field = refField?.current??null
      field.focus()
    }, 200)
    
  }

  return (
    <div className={classNames(styles.editableText)} onDoubleClick={changeMode}>{viewControl()}</div>
  )
}