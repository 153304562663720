import styles from './styles.module.scss'
import classNames from 'classnames'
import { Button } from '../button/Button'
import { Icon } from '../icons/Icon'
import { getColor } from '../../../assets/Js/modules/styles/styles.module'
import { EditableText } from '../editableText/EditableText'
import { Modal } from '../modal/Modal'
import { useState } from 'react'
import axios from 'axios'
import { AppFetch } from '../../../utils/form_module'
import { ToastContainer, toast } from 'react-toastify'
import {AppGlobalModule} from '../../../utils/default_data_app_module'


const colorRed = getColor({color:'red'})
const colorBlue = getColor({color:'blue'})
const colorGray1 = getColor({color:'gray', level:1})
const colorGray2 = getColor({color:'gray', level:2})
const colorGray3 = getColor({color:'gray', level:3})

export const Row = ({data, theme, ...props}) => {
  const [modalShow, setModalShow] = useState(()=>false)
  const [label, setLabel] = useState(data?.label??'')
  const [ENV, Authorization] = AppGlobalModule()

  if(!theme) theme = 'default'

  

  //console.log('Row - data', data)
  switch (theme) {

    default:
      const actionsView = []
      if(data.actions) {
        const actions =  data.actions
    
        //==> Eye
        if(actions.eye) {
          const iconName = `eye-${actions.eye.state}`
          const isDesabled = actions.eye.disabled
          const bgColor = isDesabled? colorGray3:colorGray1
          const handleClick = () => {
            console.log('click', iconName)
          }
          actionsView.push(
            <Button key='eye' className={classNames(styles.actionBtn, {[styles.actionBtnDisabled]:isDesabled})} disabled={isDesabled} onClick={handleClick}>
              <Icon name={iconName} size="14px" bg={bgColor}  disabled={isDesabled}/>
            </Button>
          )
        }
    
        //==> Move
        if(actions.move) {
          const isDesabled = actions.move.disabled
          const bgColor = isDesabled? colorGray3:colorBlue
          if(isDesabled) props.draggable = false
          actionsView.push(<Button key='move' className={styles.actionBtn} disabled={isDesabled}><Icon name="move" size="14px" bg={bgColor} disabled={isDesabled}/></Button>)
        } 
    
        //==> Delete
        if(actions.delete) {
          const isDesabled = actions.delete.disabled
          const bgColor = isDesabled? colorGray3:colorRed
          actionsView.push(
            <Button key='delete' className={styles.actionBtn} disabled={isDesabled} onClick={()=>setModalShow(true)}><Icon name="delete" size="14px" bg={bgColor} disabled={isDesabled}/></Button>
          )
        }
      }

      const handleFocusout = ({field, state,  handleChangeState}) => {
        const currentFieldValue = field?.value

        if(currentFieldValue) {
          const formData = new FormData()
          formData.append('id', Number(data.id))
          formData.append('name', currentFieldValue)

          AppFetch(`${ENV?.REACT_APP_API_URL}api/tag/editCategory`, {
            method: 'post',
            //url: `${ENV?.REACT_APP_API_URL}api/tag/editCategory`,
            headers: {
              'Authorization': Authorization,
            },
            body:formData
            // body: {
            //   id: Number(data.id),
            //   name: currentFieldValue
            // }
          }).then(response => {
            //console.log('Edite response', response)
            setLabel(currentFieldValue)
            toast.success("Успешно изменено!", {
              position: toast.POSITION.TOP_RIGHT
            })
          })
          handleChangeState()
        } else {

        }
      }
    
      return (
        <>
          <div className={styles.row} {...props}>
            <div className={styles.rowLabel}><EditableText value={label} handleFocusout={handleFocusout}/></div>
            <div className={styles.rowActions}>
              {actionsView}
            </div>
            {data?.actions?.delete&&!data.actions.delete.disabled? 
              <Modal show={modalShow}>
                <div>
                  modal
                </div>
              </Modal>
              :''}
          </div>
        </>
      )
      break
  }
 
}