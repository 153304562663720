import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PhoneActiveIcon from '../../../../assets/images/clientsList/table-element-phone-active.svg';
import PhoneInactiveIcon from '../../../../assets/images/clientsList/table-element-phone-inactive.svg';
import EmailActiveIcon from '../../../../assets/images/clientsList/table-element-email-active.svg';
import EmailInactiveIcon from '../../../../assets/images/clientsList/table-element-email-inactive.svg';
import MailActionsIcon from '../../../../assets/images/clientsList/actions-mail.svg';
import TagActionsIcon from '../../../../assets/images/clientsList/actions-tag.svg';
import ExportActionsIcon from '../../../../assets/images/clientsList/actions-export.svg';
import DeleteActionsIcon from '../../../../assets/images/clientsList/actions-delete.svg';
import DropdownMenuIcon from '../../../../assets/images/clientsList/head-button-more.svg';

import ClientsListDropdownMenu from '../ClientsListDropdownMenu/ClientsListDropdownMenu';
import SocialMediaIcon from '../SocialMediaIcon/SocialMediaIcon';

const ClientsListNavigationAndContent = (props) => {
    const initialContextMenu = {
        show: false,
        x: 0,
        y: 0,
    }

    const [contextMenu, setContextMenu] = useState(initialContextMenu);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'selectAll') {
            let localCheckedUsersId = props.checkedUsersId.map((user) => user);
            props.users.forEach((user) => {
                if (checked) {
                    if (localCheckedUsersId.indexOf(user.id) === -1) {
                        localCheckedUsersId.push(user.id);
                    }
                } else {
                    if (localCheckedUsersId.indexOf(user.id) !== -1) {
                        localCheckedUsersId.splice(localCheckedUsersId.indexOf(user.id), 1);
                    }
                }
            })
            props.setCheckUsersId(localCheckedUsersId);
            console.log(localCheckedUsersId)
        } else {            
            let tempUser = props.users.map( user => user.id === name ? {...user, checked: checked} : user);
            let localCheckedUsersId = props.checkedUsersId.map((id) => id);
            if (checked === true) {
                localCheckedUsersId.splice(0, 0, name);
            } else {
                localCheckedUsersId.splice(localCheckedUsersId.indexOf(name), 1);
            }
            props.setCheckUsersId(localCheckedUsersId);
            console.log(localCheckedUsersId);
            props.setUsers(tempUser);
        }
    }

    const compareArrays = (users, checkedUsersId) => {
        let match = 0
        users.forEach((user) => {
            checkedUsersId.forEach((checkedUser) => {
                if (user.id === checkedUser) {
                    match += 1;
                }
            })
        })

        if (match === users.length) {
            return true;
        } else {
            return false;
        }
    }

    function countSelected() {
        return props.checkedUsersId.length;
    }

    // ConstextMenu

    const handleClickUser = (e) => {
        e.preventDefault();

        /*const { clientX, clientY } = e;
        setContextMenu({ show: true, x: clientX, y: clientY});*/
    }

    const closeContextMenu = () => setContextMenu(initialContextMenu);
    
    return (
        <div className={classNames(styles.clientsListNavigationAndContent)}>
            { contextMenu.show && <ClientsListDropdownMenu x={contextMenu.x} y={contextMenu.y} closeContextMenu={closeContextMenu} whichCliked={'onUser'}/> }
            <div className={classNames(styles.tableContainer)}>
                <table className={classNames(styles.content)}>
                    <thead className={classNames(styles.content__titles)}>
                        <tr className={classNames(styles.content__rowContainer)}>
                            <th className={classNames(styles.content__titleContainer, styles.content__titleContainer_checkbox)}>
                                <div className={classNames(styles.content__checkboxContainer)}>
                                    <input
                                        type='checkbox'
                                        className={classNames(styles.content__checkbox)}
                                        name='selectAll'
                                        onChange={handleChange}
                                        checked={compareArrays(props.users, props.checkedUsersId)}
                                    />
                                </div>                            
                            </th>
                            <th className={classNames(styles.content__titleContainer, styles.content__titleContainer_name)}>
                                <div className={classNames(styles.content__flexContainer)}>
                                    <p className={classNames(styles.content__titleName)}>Имя</p>
                                    <button className={classNames(styles.content__titleButtonMore)} type='button'></button>                        
                                </div>                            
                            </th>
                            <th className={classNames(styles.content__titleContainer)}>
                                <div className={classNames(styles.content__flexContainer)}>
                                    <p className={classNames(styles.content__titleName)}>Город</p>
                                    <button className={classNames(styles.content__titleButtonMore)} type='button'></button>
                                </div> 
                            </th>
                            <th className={classNames(styles.content__titleContainer)}>
                                <div className={classNames(styles.content__flexContainer)}>
                                    <p className={classNames(styles.content__titleName)}>Телефон</p>
                                    <button className={classNames(styles.content__titleButtonMore)} type='button'></button>
                                </div>
                            </th>
                            <th className={classNames(styles.content__titleContainer)}>
                                <div className={classNames(styles.content__flexContainer)}>
                                    <p className={classNames(styles.content__titleName)}>Email</p>
                                    <button className={classNames(styles.content__titleButtonMore)} type='button'></button>
                                </div>
                            </th>
                            <th className={classNames(styles.content__titleContainer)}>
                                <div className={classNames(styles.content__flexContainer)}>
                                    <p className={classNames(styles.content__titleName)}>Проект</p>
                                    <button className={classNames(styles.content__titleButtonMore)} type='button'></button>
                                </div>
                            </th>
                            <th className={classNames(styles.content__titleContainer)}>
                                <div className={classNames(styles.content__flexContainer)}>
                                    <p className={classNames(styles.content__titleName)}>Каналы</p>
                                    <button className={classNames(styles.content__titleButtonMore)} type='button'></button>
                                </div>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody className={classNames(styles.content__elements)}>
                    {
                        props.users.map((user) => {
                            
                            return(
                                <tr key={user.id} className={classNames(styles.content__rowContainer)} onContextMenu={(e) => handleClickUser(e)} >
                                    <td className={classNames(styles.content__elementContainer, styles.content__elementContainer_checkbox)}>
                                        <div className={classNames(styles.content__checkboxContainer)}>
                                            <input
                                                type='checkbox'
                                                id={user.id}
                                                className={classNames(styles.content__checkbox)}
                                                name={user.id}
                                                checked={props.checkedUsersId.indexOf(user.id) !== -1 || false}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </td>
                                    <td className={classNames(styles.content__elementContainer, styles.content__elementContainer_name)}>
                                        <div className={classNames(styles.content__flexContainer)}>
                                            <div className={classNames(styles.content__elementAvatarContainer)}>
                                                <img src={user.avatar !== undefined ? user.avatar : ''} alt='' className={classNames(styles.content__elementAvatar)}/>
                                            </div>
                                            <p className={classNames(styles.content__elementText, styles.content__elementText_name)}>{user.name}</p>
                                        </div>
                                    </td>
                                    <td className={classNames(styles.content__elementContainer)}>
                                        <p className={classNames(styles.content__elementText)}>{user.city !== undefined ? user.city : '—'}</p>
                                    </td>
                                    <td className={classNames(styles.content__elementContainer)}>
                                        <div className={classNames(styles.content__flexContainer)}>
                                            <img src={user.phone ? PhoneActiveIcon : PhoneInactiveIcon} alt='телефон' className={classNames(styles.content__elementIcon)}/>
                                            <p className={classNames(styles.content__elementText, {[styles.content__elementText_none]: (user.phone === false)})}>{user.phone ? user.phone : '—'}</p>
                                        </div>
                                    </td>
                                    <td className={classNames(styles.content__elementContainer)}>
                                        <div className={classNames(styles.content__flexContainer)}>
                                            <img src={user.email ? EmailActiveIcon : EmailInactiveIcon} alt='Email' className={classNames(styles.content__elementIcon)}/>
                                            <p className={classNames(styles.content__elementText, {[styles.content__elementText_none]: (user.email === false)})}>{user.email ? user.email : '—'}</p>
                                        </div>
                                    </td>
                                    <td className={classNames(styles.content__elementContainer)}>
                                        <p className={classNames(styles.content__elementText)}>{user.site_id}</p>
                                    </td>
                                    <td className={classNames(styles.content__elementContainer)}>
                                        <ul className={classNames(styles.content__elementSocials)}>
                                            <li className={classNames(styles.content__elementSocial)}>
                                                <SocialMediaIcon socialIcon={'telegram'}/>
                                            </li>
                                            <li className={classNames(styles.content__elementSocial)}>
                                                <SocialMediaIcon socialIcon={'vk'}/>
                                            </li>
                                            <li className={classNames(styles.content__elementSocial)}>
                                                <SocialMediaIcon socialIcon={'message'}/>
                                            </li>
                                        </ul>
                                    </td>
                                    
                                    <button type='button' className={classNames(styles.content__contextMenuButton)} onClick={(e) => handleClickUser(e)}>
                                        <img src={DropdownMenuIcon} alt='Действия' className={classNames(styles.content__contextMenuButtonIcon)}/>
                                    </button>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className={classNames(styles.contentActions)}>
                <div className={classNames(styles.contentActions__leftPart)}>
                    <div className={classNames(styles.content__checkboxContainer)}>
                        <input 
                            type='checkbox'
                            className={classNames(styles.content__checkbox)}
                            name='selectAll'
                            onChange={handleChange}
                            checked={compareArrays(props.users, props.checkedUsersId)}
                        />
                    </div>
                    <p className={classNames(styles.contentActions__textCheckedAmount)}>{`${countSelected()} выбрано`}</p>
                </div>
                <div className={classNames(styles.contentActions__rightPart)}>
                    <ul className={classNames(styles.contentActions__list)}>
                        <li className={classNames(styles.contentActions__listElement)}>
                            <button type='button' className={classNames(styles.contentActions__button, styles.contentActions__button_disabled)}>
                                <img src={MailActionsIcon} alt='Написать' className={classNames(styles.contentActions__buttonIcon)}/>
                                <p className={classNames(styles.contentActions__buttonText)}>Написать</p>
                            </button>
                        </li>
                        <li className={classNames(styles.contentActions__listElement)}>
                            <button type='button' className={classNames(styles.contentActions__button, styles.contentActions__button_disabled)}>
                                <img src={TagActionsIcon} alt='Назначить тег' className={classNames(styles.contentActions__buttonIcon)}/>
                                <p className={classNames(styles.contentActions__buttonText)}>Назначить тег</p>
                            </button>
                        </li>
                        <li className={classNames(styles.contentActions__listElement)}>
                            <button type='button' className={classNames(styles.contentActions__button)}>
                                <img src={ExportActionsIcon} alt='кспортировать' className={classNames(styles.contentActions__buttonIcon)}/>
                                <p className={classNames(styles.contentActions__buttonText)}>Экспортировать</p>
                            </button>
                        </li>
                        <li className={classNames(styles.contentActions__listElement)}>
                            <button type='button' className={classNames(styles.contentActions__button, styles.contentActions__buttonDelete, styles.contentActions__button_disabled)}>
                                <img src={DeleteActionsIcon} alt='Удалить' className={classNames(styles.contentActions__buttonIcon)}/>
                                <p className={classNames(styles.contentActions__buttonText)}>Удалить</p>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ClientsListNavigationAndContent;