import { NavLink, useSearchParams, useNavigate, Navigate, useLocation } from 'react-router-dom'
import { AppGlobalModule } from '../utils/default_data_app_module';

export const RequireSiteId = ({children}) => {
  const [ENV] = AppGlobalModule()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const siteId = searchParams.get('siteId')
  const localStorageData = JSON.parse(localStorage.getItem(ENV.REACT_APP_LOCALSTORAGE_NAME))

  if(!siteId) {
    return <Navigate to='/sites'/>
  }

  
  if(localStorageData?.AuthReducer) {
    localStorageData.AuthReducer.selectedSiteId = siteId
    localStorage.setItem(ENV.REACT_APP_LOCALSTORAGE_NAME, JSON.stringify(localStorageData))
  }

  return children

  //selectedSiteId
}