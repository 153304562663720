import classNames from 'classnames'
import styles from './styles.module.scss'
import { nanoid } from 'nanoid'
import { createUseStyles } from 'react-jss'
import { useState, useRef, useEffect } from 'react'

export const Input = ({type, name, placeholder, size, height, value, label, className, fieldClassName, onFocusCallback, onBlurCallback, onChangeCallback, focus, fieldRef, required,  ...props}) => {
  const [fieldValue, setFieldValue] = useState(value??'')
  //const fieldRef = useRef()
  //console.log('input value', value, fieldValue)
  let INPUT = null
  if(!type) type = 'text'

  const useStyles = createUseStyles({
    fieldWrapper: {
      maxWidth: size??'100%'
    },
    field: {
      height: height??'36px'
    }
  })
  const classes = useStyles()

  // //==> onChange
  // const handleChange = e => {
  //   const field = e.target
  //   const filedValue = field.value
  //   //setFieldValue(filedValue)
  //   if(onChangeCallback) onChangeCallback({field})
  // }

  // //==> onFocus
  // const handleFocus = () => {
  //   if(onFocusCallback) {
  //     onFocusCallback({
  //       //field: fieldRef.current
  //     })
  //   }
  // }

  //==> onFocusout
  // const handleFocusout = () => {
  //   if(onBlurCallback) onBlurCallback({
  //     //field: fieldRef.current
  //   })
  // }

  // useEffect(()=> {
  //   //if(focus&&fieldRef) fieldRef.current.focus()
  // }, [])
  

  let SelectedInput
  switch (type) {
    case 'text':
    case 'search':
    case 'email':
    case 'password':
    case 'tel':
    case 'date':
    case 'time':
    case 'number':
      SelectedInput = (
        <>
          <label className={classNames(styles.formLabel)}>{label??''}</label>
          <input 
            type={type} 
            name={name??'field'} 
            className={classNames(styles.formField, classes.field, fieldClassName)} 
            placeholder={placeholder??''}
            // onChange={handleChange}
            // onBlur={handleFocusout}
            // onFocus={handleFocus}
            defaultValue={fieldValue}
            ref={fieldRef}
            {...props}
          />
        </>
      )
      break
    case 'checkbox':
      const fieldId = `checkbox${nanoid()}`
      SelectedInput = (
        <>
          <input 
            type={type} 
            name={name??'field'}
            className={classNames(styles.formField)} 
            ref={fieldRef}
            id={fieldId}
            {...props}
          />
          <label className={classNames(styles.formLabel)} htmlFor={fieldId}>{label??''}</label>
        </>
      )
      break
  }
  INPUT = <div className={`${classNames(styles.formRow, styles[`formRow--${type??'type'}`], classes.fieldWrapper)} ${className??null}`}>{SelectedInput}</div>
  return INPUT
}