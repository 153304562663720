import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

import telegramIcon from '../../../../assets/images/clientsList/socialMedia/telegram.svg';
import vkIcon from '../../../../assets/images/clientsList/socialMedia/vk.svg';
import messageIcon from '../../../../assets/images/clientsList/socialMedia/message.svg';

const sourceIcon = (socialIcon) => {
    switch (socialIcon) {
        case 'telegram': return telegramIcon;
        case 'vk': return vkIcon;
        case 'message': return messageIcon;
        default: return null;
    }
}

const SocialMediaIcon = (props) => {
    return (
        <div className={classNames(styles.socialMediaIcon)}>
            <img src={sourceIcon(props.socialIcon)} alt='Иконка соцсети'/>
        </div>
    );
};

export default SocialMediaIcon;